import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img1 from '../Assets/abou.svg'
import img2 from '../Assets/Group 1000005967.png'
import img3 from '../Assets/image 30.svg'
import img4 from '../Assets/1 (3).jpg'
import img5 from '../Assets/1 (5).jpg'
import img6 from '../Assets/1 (2).jpg'

function About() {
  return (
    <div className='home'>
        <Navbar/>
        <div className='about-banner'>
<div className='about-banner-left'>
    <p>ABOUT US</p>
    <br/>
    <h2>Unveiling the Heart<br/> of Our <i>Travel Passion</i></h2>
    <br/>

<h3>Halt Dmc (Destination Management Company) is among the top travel company in Thailand, assisting individuals in planning a cost-effective and enjoyable vacation. Due to the expertise of 15 years industry professionals, our company has become one of the most reliable travel partners in the country. We have professionals who have tried and tested all services to ensure that our clients travel feeling comfortable, secure, and satisfied. Additionally, we offer customized travel solutions so that our agents can plan according to their client’s tastes, requirements, and budget. We do not want our clients to feel confined or hemmed in by bundles. They are not compelled to comply with the packaging we provide. You can alter it according to your demands and needs. Explore the vast array of Thailand with Halt Dmc. In addition, we are regarded as one of the most reliable travel consultancy in Thailand. </h3>
</div>
<div className='about-banner-right'>
<img src={img1}/>
</div>
        </div>
        <div className='whoweare'>
        <p>WHO WE ARE ?</p>
     
        <h2>Our Commitment, <i>Vision, 
        Mission, And Values</i></h2>
        <br/>
        <br/>
        <br/>
<div className='vission'>
<div className='vission-left'>
<img src={img2}/>

</div>
<div className='vission-right'>
<p>Our vision at Halt Dmc is to redefine global business travel by pioneering innovative and cost-effective corporate tourism solutions worldwide. We aim to be recognized as the most trusted and creative travel consultancy in Thailand, delivering unparalleled service that inspires curiosity, exploration, and fulfillment for every client.</p>
</div>
</div>
<br/>
<br/>
<div className='vission'>
<div className='vission-right vission-right2'>
<h2>Our <i>Vision</i></h2>
<br/><br/>
<p>Exploring the unfamiliar and interacting with new experiences are inborn human tendencies that allow you to reach your fullest potential through travel. Halt Dmc endeavors to develop the most cost-effective corporate tourism on a global scale. We hope to bring in a new era of global business travel. As a result, clients in our industry have praised us for our creative travel services. Halt Dmc examines the world through the eyes of a curious traveler. We are fully aware of what it takes to provide the highest quality travel services. Our excellent team works diligently to design budget-friendly and enjoyable business excursions. Therefore, we modestly announce, “Our Vision Is the Vision of Our Clients.”</p>
</div>
<div className='vission-left'>
<img src={img3}/>

</div>

</div>
<br/>

        </div>
        <div className='Ingenuity'>
<h2>Built on Passion And <i>Ingenuity</i></h2>
<p>At the core of our foundation lies a fusion of unwavering passion <br/>and innovative thinking driving  ut to redefine the travel landscape </p>
<br/>
<br/>
<i class="bi bi-play-circle"></i>
</div>

<div className='client-count-div'>


<div className='client-count'>
<div className='client-count-card'>
<h2>95%</h2>
<p>Client Satisfaction</p>

</div>
<div className='client-count-card'>
<h2>55+</h2>
<p>Destination</p>

</div>
<div className='client-count-card'>
<h2>40+</h2>
<p>Tours & Activity</p>

</div>
<div className='client-count-card'>
<h2>24/7</h2>
<p>Customer Support</p>

</div>
</div>
</div>



<div className='journey'>
<h3>Our history</h3>
<h2>Our <i>Journey</i> Through Time</h2>
<br/>
<br/>
<div className='Journeyyear'>
<div className='Journeyyear-left'>
<h3><i>2010</i></h3>
<br/>

<h2>The Spark of Inspiration</h2>
<br/>
<p>"The Spark of Inspiration" could serve as a powerful tagline or motto for Halt Dmc, encapsulating the essence of igniting curiosity, creativity, and memorable travel experiences for all our clients.</p>
</div>
<div className='Journeyyear-right'>
<img src={img4}/>
</div>
</div>
<div className='Journeyyear'>
<div className='Journeyyear-right'>
<img src={img5}/>
</div>
<div className='Journeyyear-left'>
<h3><i>2015</i></h3>
<br/>

<h2>Expansion and Innovation</h2>
<br/>
<p>"Expansion and Innovation" aptly captures our commitment at Halt Dmc to continuously broaden our horizons and pioneer new approaches in the travel industry, ensuring exceptional service and memorable experiences for our clients.</p>
</div>

</div>
<div className='Journeyyear'>
<div className='Journeyyear-left'>
<h3><i>2024</i></h3>
<br/>

<h2>A New Era Of Exploration</h2>
<br/>
<p>Signifies our mission at Halt Dmc to usher in a fresh chapter in travel experiences, where discovery, adventure, and personalized journeys redefine how our clients explore and experience the world.</p>
</div>
<div className='Journeyyear-right'>
<img src={img6}/>
</div>
</div>
</div>
        <Footer/>
    </div>
  )
}

export default About