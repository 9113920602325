import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import WeOffer from "./Pages/WeOffer";
import Contact from "./Pages/Contact";


function App() {
  return (
    <>
    <Router>
 <Routes>
   <Route path="/" element={<Home/>} />
   <Route path="/about" element={<About/>} />
   <Route path="/weoffer" element={<WeOffer/>} />
   <Route path="/contact" element={<Contact/>} />

 </Routes>
</Router>
</>
  );
}

export default App;
