import React from 'react'
import img1 from '../Assets//972.jpg'
function BangkokSlider() {
  return (
   <>
         <div className='bangkok-section-div'>
        <div className='bangkok-section'>
<div className='bk-slides'>
<h2>Bangkok</h2>
<br/>
<br/>
<h3>Enchanting fusion of tradition and modern dynamism.</h3>
<br/>
<i><p>Mahanakhon Skywalk</p></i>
<br/>
<i><p>Siam Amazing Park</p></i>
<br/>
<i><p>Safari world marine park</p></i>
<br/>
<i><p>Wonderfull pearl Cruise from river city</p></i>
<br/>
<img src={img1}/>
</div>
</div>
        </div>
   </>
  )
}

export default BangkokSlider