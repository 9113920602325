import React from 'react'
import img from '../Assets/Group 1 (1).png'
import img2 from '../Assets/Background+Border.svg'
function HomeSlider() {
  return (
    <>
        <div className='home-slider'>
        <div className='home-slider-left'>
        <img src={img}/>

</div>
   <div className='home-slider-right'>
   <h2>We Help You Go On The<br/>
   Vacation Of <i>Your Dream</i></h2>
   <br/>
   <br/>
   <div className='slide-divs'>
    <div className='slide-div'>
    <img src={img2}/>
    <h3>Personalized
    Itineraries</h3>
    <p>Our personalized itineraries are
meticulously designed to cater
to your individual preferences.</p>

    </div>
    <div className='slide-div'>
    <img src={img2}/>
    <h3>Best Price
    Guarantee</h3>
    <p>Our Best Price Guarantee
assures you of the most
competitive rates.</p>

    </div>
   </div>

</div>
        </div>
    </>
  )
}

export default HomeSlider