import React, { useEffect, useState } from "react";
import logo from '../Assets/53082181.png'
import { Link } from 'react-router-dom'
function Navbar() {

    const [isDivVisible, setIsDivVisible] = useState(false);
  
    const toggleDivVisibility = () => {
      setIsDivVisible(!isDivVisible);
    };
  return (
    <>
        <div className='navbar'>
        <Link to='/'>
          
<img className="logo" src={logo}/>
        </Link>
<ul>
<Link className='none' to='/'>

    <li>Home</li>
</Link>
<Link className='none' to='/about'>

    <li>About Us </li>
</Link>
<Link className='none' to='/weoffer'>

    <li>Our Services </li>
</Link>

<Link className='none' to='/contact'>

<li>Contact</li>
</Link>

{/*     
    <li>Our Team</li>
    <li>Testimonials</li> */}
  
</ul>
<Link className='none' to='/contact'>

<button>CONTACT US</button>
</Link>
<i class="bi bi-list" onClick={toggleDivVisibility}></i>


        </div>
        {isDivVisible && (
        <div className="toggleDiv-ops">
          <div className="toggleDiv">
            {/* Content of the div goes here */}
            <ul>
            <Link className='none' to='/'>

<li>Home</li>
</Link>
<Link className='none' to='/about'>

<li>About Us </li>
</Link>
<Link className='none' to='/weoffer'>

<li>Our Services </li>
</Link>

<Link className='none' to='/contact'>

<li>Contact</li>
</Link>
              <br />
              <Link to='/'>
          
          <img className="logo" src={logo}/>
                  </Link>
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

export default Navbar