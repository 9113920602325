import React from 'react'
import img1 from '../Assets/hotel.png'
import img2 from '../Assets/13 (2).png'
import img3 from '../Assets/image 7.png'
import img4 from '../Assets/image 71.png'
import img5 from '../Assets/13 (5).png'
import img6 from '../Assets/13 (6).png'
import img7 from '../Assets/13 (7).png'
import img8 from '../Assets/13 (8).png'
import img9 from '../Assets/13 (9).png'
import img10 from '../Assets/13 (10).png'
import img11 from '../Assets/13 (11).png'
import img12 from '../Assets/13 (12).png'


// import img2 from '../Assets/Region - carousel.svg'
// import img3 from '../Assets/Region - carousel.svg'

function ServicesCards() {
  return (
   <>
    <div className='sercices-card-div'>
    <div className='sercices-card-heading'>
    <p>OUR SERVICES</p>
    <h2>Halt Dmc <i>Services</i></h2>
<div className='sercices-cards-div'>
<div className='sercices-cards'>
    <div className='sercices-card'>
    <img src={img1}/>
<br/>
<h3>HOTELS</h3>
<p>Pre-Purchase Deals of Hotels / Resorts / Villas are available.</p>

</div>
 <div className='sercices-card'>
    <img src={img2}/>
<br/>
<h3>TRANSFER</h3>
<p>We have our own fleet agreements for all tours, transfers, sightseeing and other requirements.</p>

</div>
 <div className='sercices-card'>
    <img src={img3}/>
<br/>
<h3>SIGHTSEEING</h3>
<p>Itinerary can be tailor made as per individual requirements.</p>

</div>
 <div className='sercices-card'>
    <img src={img4}/>
<br/>
<h3>MICE</h3>
<p>As a leading Thailand travel company, we excel in organizing MICE tours, seamlessly blending business and leisure, ensuring memorable and successful experiences.</p>

</div>
 <div className='sercices-card'>
    <img src={img5}/>
<br/>
<h3>ACTIVITIES</h3>
<p>Pre-purchase Deals of Entrance Tickets, Cruises, Water Sports Activity and Special Arrangements like Candle Light Dinner and More.</p>

</div>

<div className='sercices-card'>
    <img src={img6}/>
<br/>
<h3>
BUSINESS TRAVEL</h3>
<p>Thailand DMC excels in crafting seamless business travel, ensuring tailored experiences for success and client satisfaction.</p>

</div>
 <div className='sercices-card'>
    <img src={img7}/>
<br/>
<h3>LUXURY TRAVEL PROWESS</h3>
<p>We embrace the essence of luxury travel, curating experiences that embody opulence, exclusivity, and unparalleled indulgence for our clients.</p>

</div>
 <div className='sercices-card'>
    <img src={img8}/>
<br/>
<h3>CULTURAL IMMERSION</h3>
<p>With a deep understanding of Thailand's rich heritage, our DMC specializes in designing immersive cultural experiences, fostering a genuine connection with the destination.</p>

</div>
 <div className='sercices-card'>
    <img src={img9}/>
<br/>
<h3>LOCAL INSIGHT</h3>
<p>Leveraging extensive local knowledge, our DMC provides authentic encounters, unveiling hidden gems and ensuring an insider's perspective on Thailand.</p>

</div>
 <div className='sercices-card'>
    <img src={img10}/>
<br/>
<h3>INNOVATIVE SOLUTIONS</h3>
<p>Staying ahead in the travel industry, our DMC adopts innovative solutions, incorporating the latest trends and technologies to enhance travel experiences.</p>

</div>
 <div className='sercices-card'>
    <img src={img11}/>
<br/>
<h3>PROFESSIONAL EXCELLENCE</h3>
<p>Committed to excellence, our team of professionals ensures a seamless and stress-free experience, from planning to execution.</p>

</div>
 <div className='sercices-card'>
    <img src={img12}/>
<br/>
<h3>SUSTAINABLE TOURISM</h3>
<p>Embracing responsible tourism, our DMC integrates sustainable practices, contributing to the preservation of Thailand's natural and cultural heritage.</p>

</div>
    </div>
</div>

</div>

    </div>
   </>
  )
}

export default ServicesCards