import React from 'react'
import Navbar from '../Components/Navbar'
import ab1 from '../Assets/Group 1000005966.png'
import ab2 from '../Assets/Group 1000005965.png'
import ServicesCards from '../Components/ServicesCards'
import HomeSlider from '../Components/HomeSlider'
import buts from '../Assets/Button.svg'
import BangkokSlider from '../Components/BangkokSlider'
import OurTeam from '../Components/OurTeam'
import client from '../Assets/client.svg'
import img2 from '../Assets/s1 (1).jpg'
import img3 from '../Assets/s1 (2).jpg'
import img4 from '../Assets/s1 (3).jpg'
import img5 from '../Assets/s1 (4).jpg'
import img6 from '../Assets/s1 (5).jpg'
import img7 from '../Assets/s1 (6).jpg'
import img8 from '../Assets/1020.jpg'
import img9 from '../Assets/972.jpg'


import partners1 from '../Assets/image 18.svg'
import partners2 from '../Assets/image 19.svg'
import partners3 from '../Assets/image 20.svg'
import Footer from '../Components/Footer'

function Home() {
  return (
    <div className='home'>
        <Navbar/>
        <div className='home-banner'>
        <div className='home-banner2'>
<p><i>Tailored Travel Experiences for Unforgettable Adventures</i></p>
<h2>Craft Your Ultimate<br/>
<i>Dream</i> Getaway</h2>
<br/>

<h3>Design your perfect escape with our tailored travel services,
ensuring every detail of your dream vacation is meticulously
planned and flawlessly executed</h3>
<br/><br/>
<div className='home-btns'>
  <button className='home-btns1'>BOOK NOW</button>
  <button className='home-btns2'>LEARN NOW</button>

</div>
</div>

        </div>
        <div className='about-section'>
        <div className='about-section-1'>
<img src={ab1}/>
</div>
 <div className='about-section-2'>
 <h4>ABOUT US</h4>
 <br/>
 <h2>Halt Dmc <i>Thailand</i></h2>
 <br/>

 <p>Halt DMC, a leading travel company in Thailand with 15 years of expertise, offers customizable and affordable vacation experiences. Discover Thailand's best with our tailored travel solutions, ensuring your journey is comfortable and memorable.</p>
 <br/>
<button>READ MORE</button>
</div>
 <div className='about-section-3'>
 <img src={ab2}/>

</div>
        </div>
        <ServicesCards/>
        <HomeSlider/>
        <div className='about-thailand'>
        <div className='about-thailand-left'>
<p>ABOUT THAILAND</p>
<br/>
<br/>
<h2>Land of <i>The Smile !</i></h2>
<br/>

<h3>Halt DMC is a leading travel company in Thailand with 15 years of experience. We offer customizable, cost-effective vacation plans, ensuring comfort, security, and satisfaction. Explore Thailand with one of the most reliable travel consultancies in the country.</h3>

</div>

        </div>
     <div className='customer-section'>

     <div className='customers'>
        <div className='customers-div'>
    <h2>2K+</h2>
    <p>Happy Customer</p>
</div>
     <div className='customers-div'>
    <h2>12+</h2>
    <p>Years of Experience</p>
</div>
     <div className='customers-div'>
    <h2>100+ </h2>
    <p>Unexplored Sightseeing</p>
</div>
     {/* <div className='customers-div'>
    <h2>400</h2>
    <p>Tours Type</p>
</div> */}
        </div>


     </div>
        <div className='thai'>
        <div className='thai-text'>
          <p>Thailand</p>
          <img src={buts}/>
        </div>

        </div>
        <div className='about-thailand'>
        <div className='about-thailand-left'>
<p>PLACES</p>
<br/>
<br/>
<h2>Where do we  <i>take you?</i></h2>
<br/>
<h3>At Halt DMC, we guide you through the best 
destinations in Thailand, ensuring a memorable
 and enjoyable experience.</h3>
</div>

        </div>
        <BangkokSlider/>
        <OurTeam/>
        <div className='about-thailand'>
        <div className='about-thailand-left'>
<p>Stunning SPOTS</p>
<br/>
<br/>
<h2>Beautiful <i>Landscapes</i></h2>
<br/>
<h3>Discover Thailand's beautiful landscapes with Halt DMC. 
From pristine beaches to lush jungles and vibrant cities, 
we ensure you experience the best of Thailand's natural and cultural wonders.
</h3>
</div>

        </div>
        <br/>
        <br/>
        <div className='thai-slid'>
          <img src={img2}/>
          <img src={img3}/>
          <img src={img4}/>
          <img src={img5}/>
          <img src={img6}/>
          <img src={img7}/>
          <img src={img8}/>
          <img src={img9}/>
         
        </div>
        <br/>
        <br/>
        <br/>
<div className='contact-from'>
<div className='contact-from-div'>
<h3>CONTACT US</h3>
<h2><i>Book Your Journey</i></h2>
<br/>
<label>Name</label>
<br/>
<input/>
<br/>
<label>Email</label>
<br/>
<input/>
<br/>
<label>Number</label>
<br/>
<input/>
<br/>
<label>Date</label>
<br/>
<input/>
<br/>
<br/>


<button>Book Now</button>
</div>
</div>
<div className='testimonials'>
<p>TESTIMONIALS</p>
<h2>What Over <i>Client Say</i></h2>

<br/>
<br/>
<br/>
<div className='test-div'>
<div className='testimonials-cards'>
<div className='testimonials-card'>
<div className='stars'>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
</div>
<br/>
<br/>
<br/>
<br/>

<p>Thanks to the team for timely providing all information along with the best resort for us as per our requirement. Kudos to Halt Dmc team. </p>
<br/>
<br/>
<br/>

<div className='client-img'>
  {/* <img src={client}/> */}
  <h4>Vijay Kumar  </h4>
</div>

</div>
<div className='testimonials-card'>
<div className='stars'>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
</div>
<br/>
<br/>
<br/>
<br/>

<p>Had a very good experience. Their rates and services are exceptionally well. Absolute satisfaction. </p>
<br/>
<br/>
<br/>

<div className='client-img'>
 
  <h4>Chetna Sharma</h4>
</div>

</div>
<div className='testimonials-card'>
<div className='stars'>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
</div>
<br/>
<br/>
<br/>
<br/>

<p>Happy and impressed with team response. Catered all our queries and offer the best rates for all the tours.</p>
<br/>
<br/>
<br/>

<div className='client-img'>
  
  <h4>Tarun Mehta </h4>
</div>

</div>
<div className='testimonials-card'>
<div className='stars'>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
<i class="bi bi-star"></i>
</div>
<br/>
<br/>
<br/>
<br/>

<p>Good experience, good execution and very customer friendly organization. Quick response. </p>
<br/>
<br/>
<br/>

<div className='client-img'>
 
  <h4>Ajay Saxena </h4>
</div>

</div>


</div>
</div>
<br/>
<br/>
<br/>

</div>
<div className='partners'>
<p>PARTNER</p>
<h2>Our <i>Partner</i></h2>
<br/>
<br/>
<br/>
<div className='partners-slide'>

    <div className="marquee">
      <img src={partners1} alt="Partner 1"/>
      <img src={partners2} alt="Partner 1"/>
      <img src={partners3} alt="Partner 1"/>
      <img src={partners1} alt="Partner 1"/>
      <img src={partners2} alt="Partner 1"/>
      <img src={partners3} alt="Partner 1"/>
      <img src={partners1} alt="Partner 1"/>
      <img src={partners2} alt="Partner 1"/>
      <img src={partners3} alt="Partner 1"/>
      <img src={partners1} alt="Partner 1"/>
      <img src={partners2} alt="Partner 1"/>
      <img src={partners3} alt="Partner 1"/>
      <img src={partners1} alt="Partner 1"/>
      <img src={partners2} alt="Partner 1"/>
      <img src={partners3} alt="Partner 1"/>
      <img src={partners1} alt="Partner 1"/>
      <img src={partners2} alt="Partner 1"/>
      <img src={partners3} alt="Partner 1"/>
    </div>
  </div>
 
</div>
<Footer/>

    </div>
  )
}

export default Home