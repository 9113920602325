import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img1 from '../Assets/Halt DMC Web/Group 1000005968.png'
import img from '../Assets/Region - carousel.svg'
import img2 from '../Assets/Region - carousel.svg'
import img3 from '../Assets/Region - carousel.svg'
import ServicesCards from '../Components/ServicesCards'

function WeOffer() {
  return (
   <div className='home'>
    <Navbar/>
    <div className='about-banner'>
<div className='about-banner-left'>
    <p>WE OFFER</p>
    <br/>
    <h2>Halt Dmc <i>Services</i></h2>
    <br/>

<h3>We offer expertly tailored, cost-effective travel solutions across Thailand, ensuring comfortable, secure, and customizable experiences that cater to the unique preferences and budgets of our clients.</h3>
</div>
<div className='about-banner-right'>
<img src={img1}/>
</div>
        </div>
        <ServicesCards/>
    <Footer/>
   </div>
  )
}

export default WeOffer