import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/Halt DMC Web/1 (4).jpg'
import img2 from '../Assets/1305.jpg'
function Contact() {
  return (
    <div className='home'>
        <Navbar/>
        <div className='contact'>
<p>CONTACT</p>
<h2>Contact Us</h2>
<br/>
<h3>Delve into the essence of our travel passion as we unveil the beating heart behind every <br/>journey, driven by a relentless pursuit of exploration and discovery.</h3>
        </div>




        
        <div className='booking'>
        <div className='booking-left'>
        <h2>Book your unforgettable travel journey now!</h2>
        <br/>
        <br/>
        <p>Embark on an unforgettable travel journey with our expert assistance. Let us handle the details while you focus on creating lasting memories. Our dedicated team is here to ensure your trip is seamless and stress-free from start to finish. Let us turn your travel dreams into reality.</p>
        <br/>
        <br/>

<img src={img}/>
</div>
  <div className='booking-right'>
<div className='msg-box'>
<h2>Send Us a Message</h2>
<br/>
<br/>


<input placeholder='Your Name' />
<br/>
<br/>


<input placeholder='Your Email'/>
<br/>
<br/>



<textarea rows="8"  placeholder='Your Text'/>
<br/>
<br/>

<br/>

<button>SUBMIT</button>
</div>
</div>
        </div>
        <div className='LOCATION'>
        <p>LOCATION</p>
        <h2>Our <i>Location</i></h2>
        <div className='LOCATION-div'>
<img src={img2}/>
<br/>
<h2>Head Office</h2>
<div className='office-address'>

<p><i class="bi bi-geo-alt-fill"></i>55 119 Soi Phru Yai, Ratsada, Mueang Phuket District, Phuket 83000, Thailand</p>
{/* <p>
<i class="bi bi-telephone-fill"></i>
 9874563211</p> */}

<p><i class="bi bi-envelope-fill"></i> ops.thailand@haltdmc.com</p>
</div>
<br/>
<h2>Sales Offfice </h2>
<div className='office-address'>

<p><i class="bi bi-geo-alt-fill"></i>405-B, The Iconic Tower-C Corenthum, Sector-62 ,Noida Electronic City -201309</p>
{/* <p>
<i class="bi bi-telephone-fill"></i>
 9874563211</p> */}

<p><i class="bi bi-envelope-fill"></i> ops.thailand@haltdmc.com</p>
</div>
        </div>

        </div>
        <div className='discover'>
        <h2>Discover the<br/> World <i>With Us</i> </h2>
        </div>
        <div >
        <iframe className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.0717309235197!2d77.3709822749566!3d28.627612184305125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5574455d57b%3A0xf19bca2bae503f87!2sIconic%20Tower!5e0!3m2!1sen!2sin!4v1720672511614!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
       
        </div>

        <Footer/>
    </div>
  )
}

export default Contact