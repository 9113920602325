import React from 'react'
import logo from '../Assets/53082181.png'
import social1 from '../Assets/social (1).svg'
import social2 from '../Assets/social (2).svg'
import social3 from '../Assets/social (3).svg'
import social4 from '../Assets/social (4).svg'
import social5 from '../Assets/social (5).svg'
import { Link } from 'react-router-dom'

function Footer() {
  return (
   <>
    <div className='getintouch'>
    <h1>Let’s Get in Touch</h1>
  </div>
  <div className='footer'>
  <div className='footer-div'>
  <ul>
    <li>
        <img className='logo' src={logo}/>
    </li>
    <br/>
    <br/>


    <li className='social-media-icons'>
    <i class="bi bi-instagram"></i>
    <i class="bi bi-facebook"></i>
    <i class="bi bi-whatsapp"></i>
    <i class="bi bi-youtube"></i>
 

    </li>
  </ul>
  <div className='foot-ul'>
  <ul >
  <Link className='none' to='/'>

    <li>Home</li>
  </Link>
    <Link className='none' to='/about'>

    <li>About Us</li>
</Link>
    <Link className='none' to='/weoffer'>

    <li>Our Services</li>
</Link>
    <Link className='none' to='/contact'>

    <li>Contact US</li>
</Link>
   

</ul>



<p>© Halt Dmc 128 CMS. All Rights Reserved 2024. </p>
  </div>
  <ul className='foot-ul-last'>
    <p>Get In Touch</p>
    <br/>
    
<div className='footeript'>
<input placeholder='Email' />
<i class="bi bi-arrow-right"></i>
</div>
  </ul>

</div>
  </div>
  <div className='bottom-footer'>
  <p>Designed & Developed by <Link className='none2' to='https://www.strixdigital.in/'>
    <span>Strix Digital</span>
  </Link></p>

  </div>
   </>
  )
}

export default Footer